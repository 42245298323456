import React, {Component} from "react";
import css_classes from '../style.module.css';
import styles from "../../Starmus8/style.module.css";
import PreRegForm from "../../Starmus7/Sections/preRegForm";
import Configs from "../../configs";
import {ProgramItem} from "../../StarmusArmenia/ProgramItem";
import {Switcher} from "../../components/Switcher/Switcher";

export class Agenda extends Component {

    constructor(props) {
        super();
        this.festival_id = props.content.festival_id;
        this.title = props.content.title;
        this.state = {agenda: [], groups: [], group_info: [], days: []};
    }

    componentDidMount() {
        fetch(`${Configs.api_url}data/get-agenda-groups?festival_id=${this.festival_id}`)
            .then(response => response.json())
            .then(data => {
                    if (data.length) {
                        this.setState({
                            groups: data
                        })
                        this.getData(this.festival_id, data[0]['id'])
                    }


                }
            );

    }

    getData(festival_id, type) {
        // Loader.startLoading();
        // window.Loader.startLoading();
        fetch(`${Configs.api_url}data/get-agenda2?festival_id=${festival_id}&type=${type}`)
            .then(response => response.json())
            .then(data => {
                    let index = 0;
                    let days = [];
                    for (let key in  data.agenda) {
                        let program_data = data.agenda[key].program
                        let persons = data.agenda[key].persons
                        days.push(<ProgramItem key={'pi_l_'+index+'_' + data.group_info.id} data={program_data} persons={persons}/>)
                        index++;
                    }
                    this.setState({
                        agenda: data.agenda,
                        group_info: data.group_info,
                        days: days,
                    })
                }
            );
    }

    switchType = (type) => {
        this.getData(this.festival_id, type);
    }


    render() {

        let switcher = '';
        if (this.state.groups.length) {
            switcher = <Switcher keys={this.state.groups} switcherFunction={this.switchType}></Switcher>
        }
        return <div className={css_classes.agenda_wrap + ' ' + css_classes.page}>
            <div className={'am_container'}>
                {switcher}
                <div className={css_classes.title + ' text_center ' + css_classes.title_black}
                     dangerouslySetInnerHTML={{__html: this.state.group_info.text_title}}></div>
                <div>

                    <div className={css_classes.agenda_text}
                         dangerouslySetInnerHTML={{__html: this.state.group_info.text}}></div>
                        {this.state.days}
                </div>
            </div>
        </div>
    }
}
